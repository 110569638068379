import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './team-builder.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { IHSRCharacter } from '../../modules/common/model/graphql-types';
import {
  Alert,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import {
  faCircleQuestion,
  faTriangleExclamation,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-switch';
import { HSRTeamBuilderSingleCharacter } from '../../modules/hsr/team-builder/custom-stats';

interface IHSRCharacterNodes {
  nodes: IHSRCharacter[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRDPSTeamBuilderPage: React.FC<IProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fullList, setFullList] = useState(data.allCharacters.nodes);
  const [favList, setFavList] = useState([]);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [customTurns, setCustomTurns] = useState(7);
  const [diff1, setDiff1] = useState(0);
  const [diff2, setDiff2] = useState(0);
  const [diff3, setDiff3] = useState(0);
  const [diff4, setDiff4] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const onChangeTurns = (event) => {
    const limit = 2;
    if (event.target.value > 0) {
      setCustomTurns(event.target.value.slice(0, limit));
    } else {
      setCustomTurns(1);
    }
  };

  const modeChangeHandler = (e) => {
    setAdvancedMode(e);
  };

  const resetStuff = () => {
    setFavList([]);
    setCustomTurns(7);
    setAdvancedMode(false);
    setTotalCost(0);
  };

  const handleFavAddClick = (e) => {
    const findItem = fullList.find((item) => item.unitId === e);

    if (findItem) {
      const checkIfIsFav = favList.find((item) => item.unitId === e);
      if (!checkIfIsFav) {
        setFavList([
          ...favList,
          {
            unitId: findItem.unitId,
            name: findItem.name,
            energyUltimate: findItem.energyUltimate,
            baseSpeed: findItem.stats.speed_base,
            customSpeed: findItem.stats.speed_base,
            slug: findItem.slug,
            defaultRole: findItem.defaultRole,
            difference: 0
          }
        ]);
      } else {
        setFavList(favList.filter((item) => item.unitId !== e));
      }
    }
  };

  const handleRemoveClick = (e) => {
    setFavList(favList.filter((item) => item.unitId !== e));
  };

  useEffect(() => {
    setTotalCost(diff1 + diff2 + diff3 + diff4 + 3);
  }, [diff1, diff2, diff3, diff4]);

  const rolePopover = (
    <Popover id="popover-element">
      <Popover.Header as="h3">Roles explained</Popover.Header>
      <Popover.Body>
        <p>
          <strong>Main damage</strong> - Character who consumes the majority of
          the teams skill points and aims to use their skill as much as possible
          to carry the team (DPS / Breaker).
        </p>
        <p>
          <strong>Sub damage</strong> - Character who supports the main carry by
          spending the remainder of teams skill points (Buffer / Debuffer /
          Off-Dps / Shielder / Breaker).
        </p>
        <p>
          <strong>Healer</strong> - Skill Point Generator that keeps the team
          alive.
        </p>
        <p>
          <strong>Shielder</strong> - Skill Point Generator that keeps the team
          alive.
        </p>
        <p>
          <strong>Support</strong> - Skill Point Generator that supports the
          objective of the team (Buffer / Debuffer / Breaker).
        </p>
      </Popover.Body>
    </Popover>
  );

  return (
    <DashboardLayout className={'generic-page team-builder'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Builder</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_teambuilder.png"
            alt="Team Builder"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Team Builder</h1>
          <h2>
            A handy team builder to easier plan your teams in Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This is an early version of our team builder that only has the most
          necessary functionalities available. We will continue to expand it
          till the release by adding: Relics, Light Cones, showing all stats not
          just Speed etc.
        </p>
        <p>
          Be aware that{' '}
          <strong>
            refreshing the page or moving to another will reset the data
          </strong>{' '}
          - sessions storage will also be added in a future version. Also there
          are some issues with data not refreshing properly when you remove a
          character from the team in the advanced mode. Till its fixed we
          suggest to start playing with the advanced mode after selecting the 4
          characters in your team.
        </p>
        <SectionHeader
          title="Team Builder"
          firstControl={
            <Button
              onClick={resetStuff}
              variant="primary"
              className="remove-me"
            >
              <FontAwesomeIcon icon={faXmark} width="16" /> Reset team
            </Button>
          }
          customClass="section-reset"
        />
        <Button
          onClick={resetStuff}
          variant="primary"
          className="remove-me button-mobile"
        >
          <FontAwesomeIcon icon={faXmark} width="16" /> Reset team
        </Button>
        <Row xs={1} xl={2} xxl={4} className="current-team">
          {favList.map((e, index) => (
            <HSRTeamBuilderSingleCharacter
              index={index}
              handleRemoveClick={handleRemoveClick}
              advancedMode={advancedMode}
              customTurns={customTurns}
              element={e}
              key={index}
              totalCost={totalCost}
              setTotalCost={setTotalCost}
              favList={favList}
              setDiff1={setDiff1}
              setDiff2={setDiff2}
              setDiff3={setDiff3}
              setDiff4={setDiff4}
            />
          ))}
          {favList.length < 1 && (
            <Col>
              <div className="box placeholder-spot role-0">
                <div className="select-role">
                  <Form.Select
                    aria-label="selected-role"
                    disabled
                    defaultValue="role-0"
                  >
                    <option value="role-0">Main Damage Dealer</option>
                    <option value="role-1">Sub Damage Dealer</option>
                    <option value="role-2">Healer</option>
                    <option value="role-3">Shielder</option>
                    <option value="role-4">Support</option>
                  </Form.Select>
                  <div className="help">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto-start"
                      overlay={rolePopover}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        width="22"
                        className="remove"
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className="pill speed">
                      <strong>?</strong> speed
                    </div>
                    <div className="pill speed">
                      <strong>?</strong> energy cost
                    </div>
                  </div>
                  <div className="base-info action-value">
                    <div className="pill speed">
                      <strong>?</strong> actions per battle
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 2 && (
            <Col>
              <div className="box placeholder-spot role-1">
                <div className="select-role">
                  <Form.Select
                    aria-label="selected-role"
                    disabled
                    defaultValue="role-1"
                  >
                    <option value="role-0">Main Damage Dealer</option>
                    <option value="role-1">Sub Damage Dealer</option>
                    <option value="role-2">Healer</option>
                    <option value="role-3">Shielder</option>
                    <option value="role-4">Support</option>
                  </Form.Select>
                  <div className="help">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto-start"
                      overlay={rolePopover}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        width="22"
                        className="remove"
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="character-box-empty ">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className="pill speed">
                      <strong>?</strong> speed
                    </div>
                    <div className="pill speed">
                      <strong>?</strong> energy cost
                    </div>
                  </div>
                  <div className="base-info action-value">
                    <div className="pill speed">
                      <strong>?</strong> actions per battle
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 3 && (
            <Col>
              <div className="box placeholder-spot role-2">
                <div className="select-role">
                  <Form.Select
                    aria-label="selected-role"
                    disabled
                    defaultValue="role-2"
                  >
                    <option value="role-0">Main Damage Dealer</option>
                    <option value="role-1">Sub Damage Dealer</option>
                    <option value="role-2">Healer</option>
                    <option value="role-3">Shielder</option>
                    <option value="role-4">Support</option>
                  </Form.Select>
                  <div className="help">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto-start"
                      overlay={rolePopover}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        width="22"
                        className="remove"
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className="pill speed">
                      <strong>?</strong> speed
                    </div>
                    <div className="pill speed">
                      <strong>?</strong> energy cost
                    </div>
                  </div>
                  <div className="base-info action-value">
                    <div className="pill speed">
                      <strong>?</strong> actions per battle
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {favList.length < 4 && (
            <Col>
              <div className="box placeholder-spot role-4">
                <div className="select-role">
                  <Form.Select
                    aria-label="selected-role"
                    disabled
                    defaultValue="role-4"
                  >
                    <option value="role-0">Main Damage Dealer</option>
                    <option value="role-1">Sub Damage Dealer</option>
                    <option value="role-2">Healer</option>
                    <option value="role-3">Shielder</option>
                    <option value="role-4">Support</option>
                  </Form.Select>
                  <div className="help">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="auto-start"
                      overlay={rolePopover}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        width="22"
                        className="remove"
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="character-box-empty">
                  <div className="placeholder-icon"></div>
                  <h5>Empty spot</h5>
                  <div className="base-info">
                    <div className="pill speed">
                      <strong>?</strong> speed
                    </div>
                    <div className="pill speed">
                      <strong>?</strong> energy cost
                    </div>
                  </div>
                  <div className="base-info action-value">
                    <div className="pill speed">
                      <strong>?</strong> actions per battle
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {advancedMode && (
          <div className="skill-points-summary">
            {favList.length === 4 ? (
              <>
                {totalCost === 0 ? (
                  <Alert variant="primary">
                    <p>
                      Your team achieved a{' '}
                      <strong className="great">perfect balance</strong> and in{' '}
                      <strong>{customTurns}</strong> cycle battle it will
                      consume all generated skill points (and the 3 skill points
                      you start the battle with).
                    </p>
                  </Alert>
                ) : (
                  <>
                    {totalCost < 0 && (
                      <Alert variant="primary">
                        <p>
                          Your team requires{' '}
                          <strong className="consumed">
                            {Math.abs(totalCost)} more skill points to operate
                          </strong>{' '}
                          than it can generate in a{' '}
                          <strong>{customTurns}</strong> cycle battle (including
                          the 3 skill points you start the battle with).
                        </p>
                        <p className="sub-text">
                          This will lead to empty cycles where you will need to
                          use Basic Attack more than you want.
                        </p>
                      </Alert>
                    )}

                    {totalCost > 0 && (
                      <Alert variant="primary">
                        <p>
                          Your team will end the <strong>{customTurns}</strong>{' '}
                          cycle battle with{' '}
                          <strong className="generated">
                            {Math.abs(totalCost)} remaining skill points
                          </strong>{' '}
                          (including the 3 skill points you start the battle
                          with).
                        </p>
                        <p className="sub-text">
                          Since you let your skill points overflow, your team's
                          damage output will be affected.
                        </p>
                      </Alert>
                    )}
                  </>
                )}
              </>
            ) : (
              <Alert variant="primary">
                <p>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    width="16"
                    className="red"
                  />{' '}
                  Please add 4 characters to see the team's skill points
                  summary.
                </p>
              </Alert>
            )}
          </div>
        )}
        <div className="advanced-mode-section">
          <div className="switch-advanced">
            <h5>Advanced mode</h5>
            <Switch
              checked={advancedMode}
              disabled={favList.length === 0}
              onChange={modeChangeHandler}
              onColor="#009EEC"
              offColor="#484950"
              className="switch"
            />
          </div>
          {advancedMode && (
            <div className="advanced-mode-container">
              <p>
                Modifying the Cycle value allows you to adjust the length of the
                battle (default: 7 - that's the number of maximum cycles
                required to 3* deeper Forgotten Hall stages).
              </p>
              <div className="advanced-settings">
                <FloatingLabel
                  controlId="customCylces"
                  label="Custom Cycles"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    min="1"
                    max="99"
                    name="Custom Cycles"
                    onChange={onChangeTurns}
                    value={customTurns}
                    className="form-control"
                  />
                </FloatingLabel>
              </div>
            </div>
          )}
        </div>
        <h5>Characters</h5>
        <div className={`employees-container`}>
          {fullList.map((e, index) => {
            const isFav = favList.find((item) => item.unitId === e.unitId);
            return (
              <span key={index}>
                {isFav || favList.length < 4 ? (
                  <Card
                    onClick={() => handleFavAddClick(e.unitId)}
                    className={`avatar-card ${isFav ? 'selected' : ''}`}
                  >
                    <HSRCharacter
                      slug={e.slug}
                      mode="icon-no-link"
                      showLabel
                      showIcon
                    />
                  </Card>
                ) : (
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug={e.slug}
                      mode="icon-no-link"
                      showLabel
                      showIcon
                    />
                  </Card>
                )}
              </span>
            );
          })}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HSRDPSTeamBuilderPage;

export const Head: React.FC = () => (
  <Seo
    title="Team Builder | Honkai: Star Rail | Prydwen Institute"
    description="A handy team builder to easier plan your teams in Honkai: Star Rail."
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        path
        energyUltimate
        defaultRole
        stats {
          hp_base
          def_base
          atk_base
          speed_base
        }
      }
    }
  }
`;
