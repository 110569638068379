/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import { HSRCharacter } from '../common/components/hsr-character';

interface IProps {
  index: number;
  handleRemoveClick(i: string, f: number): void;
  element: any;
  customTurns: number;
  advancedMode: boolean;
  totalCost: number;
  setTotalCost(i: number): void;
  favList: any;
  setDiff1: any;
  setDiff2: any;
  setDiff3: any;
  setDiff4: any;
}

export const HSRTeamBuilderSingleCharacter: React.FC<IProps> = (props) => {
  const [pointsSkill, setPointsSkill] = useState(0);
  const [pointsBasic, setPointsBasic] = useState(
    Math.floor(Number(ActionsPerBattle(props.element.customSpeed)))
  );
  const skillDifference = pointsBasic - pointsSkill;
  const [currentRole, setCurrentRole] = useState(props.element.defaultRole);
  const [finalSpeed, setFinalSpeed] = useState(props.element.baseSpeed);
  const [flatSpeed, setFlatSpeed] = useState(0);
  const [percentageSpeed, setPercentageSpeed] = useState(0);

  const rolePopover = (
    <Popover id="popover-element">
      <Popover.Header as="h3">Roles explained</Popover.Header>
      <Popover.Body>
        <p>
          <strong>Main damage</strong> - Character who consumes the majority of
          the teams skill points and aims to use their skill as much as possible
          to carry the team (DPS / Breaker).
        </p>
        <p>
          <strong>Sub damage</strong> - Character who supports the main carry by
          spending the remainder of teams skill points (Buffer / Debuffer /
          Off-Dps / Shielder / Breaker).
        </p>
        <p>
          <strong>Healer</strong> - Skill Point Generator that keeps the team
          alive.
        </p>
        <p>
          <strong>Shielder</strong> - Skill Point Generator that keeps the team
          alive.
        </p>
        <p>
          <strong>Support</strong> - Skill Point Generator that supports the
          objective of the team (Buffer / Debuffer / Breaker).
        </p>
      </Popover.Body>
    </Popover>
  );

  function ActionsPerBattle(value) {
    const baseSpeed = value;
    const cycles = props.customTurns * 100 + 50;
    const apt = cycles / (10000 / baseSpeed);
    return apt.toFixed(2);
  }

  const onChangeFlat = (event) => {
    const limit = 3;
    if (event.target.value > 0) {
      setFlatSpeed(event.target.value.slice(0, limit));
    } else {
      setFlatSpeed(0);
    }
  };

  const onChangePerc = (event) => {
    const limit = 3;
    if (event.target.value > 0) {
      setPercentageSpeed(event.target.value.slice(0, limit));
    } else {
      setPercentageSpeed(0);
    }
  };

  const onChangeHandlerBasic = (event) => {
    const limit = 2;
    const customSpeedCurrent = Math.floor(Number(ActionsPerBattle(finalSpeed)));

    if (event.target.value <= customSpeedCurrent) {
      setPointsBasic(event.target.value.slice(0, limit));
    } else {
      setPointsBasic(customSpeedCurrent);
    }
  };

  const onChangeHandlerSkill = (event) => {
    const limit = 2;
    const customSpeedCurrent = Math.floor(Number(ActionsPerBattle(finalSpeed)));

    if (event.target.value <= customSpeedCurrent) {
      setPointsSkill(event.target.value.slice(0, limit));
    } else {
      setPointsSkill(customSpeedCurrent);
    }
  };

  useEffect(() => {
    setCurrentRole(props.element.defaultRole);
  }, [props.favList]);

  useEffect(() => {
    const tempspeed =
      Number(props.element.baseSpeed) * (1 + Number(percentageSpeed) / 100) +
      Number(flatSpeed);
    setFinalSpeed(tempspeed);
  }, [flatSpeed, percentageSpeed]);

  useEffect(() => {
    if (props.index === 0) {
      props.setDiff1(skillDifference);
    }
    if (props.index === 1) {
      props.setDiff2(skillDifference);
    }
    if (props.index === 2) {
      props.setDiff3(skillDifference);
    }
    if (props.index === 3) {
      props.setDiff4(skillDifference);
    }
  }, [skillDifference]);

  return (
    <Col>
      <div className={`box single-character ${currentRole}`}>
        <div className="select-role">
          <Form.Select
            aria-label="selected-role"
            value={currentRole}
            onChange={(event) => setCurrentRole(event.target.value)}
          >
            <option value="Main DPS">Main Damage Dealer</option>
            <option value="Sub DPS">Sub Damage Dealer</option>
            <option value="Healer">Healer</option>
            <option value="Shielder">Shielder</option>
            <option value="Support">Support</option>
          </Form.Select>
          <div className="help">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="auto-start"
              overlay={rolePopover}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                width="22"
                className="remove"
              />
            </OverlayTrigger>
          </div>
        </div>
        <div className="character-box">
          <Card
            className="avatar-card"
            onClick={() =>
              props.handleRemoveClick(props.element.unitId, skillDifference)
            }
          >
            <HSRCharacter
              slug={props.element.slug}
              mode="icon-no-link"
              showIcon
            />
            <FontAwesomeIcon icon={faXmark} width="32" className="remove" />
          </Card>
          <h5>{props.element.name}</h5>
          <div className="base-info">
            <div className="pill speed">
              <strong>
                {props.advancedMode
                  ? Math.floor(finalSpeed)
                  : props.element.baseSpeed}
              </strong>{' '}
              {props.advancedMode && 'custom'} speed
            </div>
            <div className="pill speed">
              <strong>{props.element.energyUltimate}</strong> energy cost
            </div>
          </div>
          <div className="base-info action-value">
            <div className="pill speed">
              <strong>
                {props.advancedMode
                  ? ActionsPerBattle(finalSpeed)
                  : ActionsPerBattle(props.element.baseSpeed)}
              </strong>{' '}
              actions per battle ({props.customTurns ? props.customTurns : '?'}{' '}
              cycles)
            </div>
          </div>
          {props.advancedMode && (
            <div className="advanced-mode">
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="customSpeedFloat"
                    label="Flat bonus Speed"
                  >
                    <Form.Control
                      type="number"
                      min="1"
                      max="999"
                      name="custom-flat-speed"
                      onChange={onChangeFlat}
                      value={flatSpeed}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="customSpeedFloat"
                    label="% bonus Speed"
                  >
                    <Form.Control
                      type="number"
                      min="1"
                      max="999"
                      name="custom-perc-speed"
                      onChange={onChangePerc}
                      value={percentageSpeed}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="customPointsBasic"
                    label="Basic Used"
                  >
                    <Form.Control
                      type="number"
                      min="0"
                      max={
                        props.advancedMode
                          ? ActionsPerBattle(finalSpeed)
                          : ActionsPerBattle(props.element.baseSpeed)
                      }
                      name="custom-speed"
                      onChange={onChangeHandlerBasic}
                      value={pointsBasic}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="customPointsSkill"
                    label="Skill Used"
                  >
                    <Form.Control
                      type="number"
                      min="0"
                      max={
                        props.advancedMode
                          ? ActionsPerBattle(finalSpeed)
                          : ActionsPerBattle(props.element.baseSpeed)
                      }
                      name="custom-speed"
                      onChange={onChangeHandlerSkill}
                      value={pointsSkill}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <p className="skill-points">
                {skillDifference === 0 ? (
                  <span className="waiting">
                    No skill points generated or consumed.
                  </span>
                ) : (
                  <>
                    <strong
                      className={`${
                        skillDifference > 0 ? 'generated' : 'consumed'
                      }`}
                    >
                      {Math.abs(skillDifference)}
                    </strong>{' '}
                    skill points{' '}
                    {skillDifference > 0 ? (
                      <span className="generated">generated.</span>
                    ) : (
                      <span className="consumed">consumed.</span>
                    )}{' '}
                  </>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};
